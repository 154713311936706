import React from "react"
import cx from "classnames"
import useNavigationData from "../../hooks/use-navigation-data"
import styles from "./navigation.module.scss"
import Link from "../Link"

const Navigation = () => {
  const data = useNavigationData()
  return (
    <div className={styles.heroNav}>
      <div className={cx(styles.navRow, "row")}>
        <nav className="large-12 columns">
          <ul className={cx(styles.inlineList, "float-left")}>
            <li className={styles.navSkip}>
              <a href="#start-of-content">Skip to content</a>
            </li>
            {data.body.map(item => {
              if (item.primary.item_type === "Link") {
                return (
                  <li key={item.primary.label.text}>
                    <Link className={styles.navItem} to={item.primary.link.url}>
                      {item.primary.label.text}
                    </Link>
                  </li>
                )
              }
              if (
                item.primary.item_type === "Language" &&
                typeof window === "object"
              ) {
                return (
                  <li key={item.primary.label.text}>
                    <Link
                      className={styles.navItem}
                      to={item.primary.link.url + window.location.pathname}
                    >
                      {item.primary.label.text}
                    </Link>
                  </li>
                )
              }
              return <></>
            })}
          </ul>
          <ul className={cx(styles.inlineList, styles.navBtn, "float-right")}>
            <li>
              <Link
                className={styles.applyBtn}
                to={data.button_link.url}
                target={data.button_link.target}
              >
                {data.button_text.text}
              </Link>
              {/* <a
                className={styles.applyBtn}
                href={data.button_link.url}
                target={data.button_link.target}
              >
                {data.button_text.text}
              </a> */}
            </li>
          </ul>
        </nav>
      </div>
      <div id="start-of-content" />
    </div>
  )
}

export default Navigation
