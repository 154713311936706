import { useEffect } from "react"

const callbacks = {}
const handleResize = () => {
  Object.values(callbacks).forEach(callback => callback())
}
if (typeof window === "object") {
  window.addEventListener("resize", handleResize)
}

/**
 * Perform an action on window resize, typically used to polyfill flexbox
 * on older IE versions.
 * @param {Function} callback
 */
const useWindowResize = callback => {
  useEffect(() => {
    const callbackId = Date.now().toString()
    callback()
    callbacks[callbackId] = callback
    return () => delete callbacks[callbackId]
  }, [])
}

/**
 * Dispatch a resize event (to notify flexbox polyfills of layout changes)
 */
export const triggerResize = () => {
  if (typeof window === "object" && typeof document === "object") {
    const evt = document.createEvent("Event")
    evt.initEvent("resize", true, false)
    window.dispatchEvent(evt)
  }
}

export default useWindowResize
