import { useStaticQuery, graphql } from "gatsby"

const useNavigationData = () => {
  const data = useStaticQuery(graphql`
    {
      prismicNavigation {
        data {
          body {
            ... on PrismicNavigationBodyMenuItem {
              primary {
                label {
                  text
                }
                link {
                  url
                }
                item_type
              }
            }
          }
          button_link {
            url
            target
          }
          button_text {
            text
          }
        }
      }
    }
  `)
  return data.prismicNavigation.data
}

export default useNavigationData
