import React from "react"
import PropTypes from "prop-types"

import "../../scss/main.scss"
import "./layout.module.scss"
import Navigation from "../Navigation"
import Footer from "../Footer"
import AccessibilityButton from "../AccessibilityButton"
import Submitting from "../Submitting"

/**
 * Wrapper for site layout
 */
const Layout = ({ children, showHeader = true, colour, submitting }) => {
  return (
    <AccessibilityButton id="a11ybutton">
      {showHeader && <Navigation />}
      {submitting && <Submitting />}
      <main style={{ backgroundColor: colour }}>{children}</main>
      <Footer />
    </AccessibilityButton>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
