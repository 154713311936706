/**
 * Returns the version of IE being used (between 9 and 11)
 * Anything 8 or below returns 8
 * Note: Required DOM load
 */
const useIEVersion = () => {
  if (
    typeof window !== "undefined" &&
    typeof document !== "undefined" &&
    window.ActiveXObject !== undefined
  ) {
    if (!document.addEventListener) return 8
    if (!window.atob) return 9
    if (!window.MutationObserver) return 10
    return 11
  }
  return null
}

export default useIEVersion
