import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import cx from "classnames"
import useFooterData from "../../hooks/use-footer-data"
import styles from "./footer.module.scss"

/**
 * Render site footer (does not use parameters, see useFooterData hook)
 */
const Footer = () => {
  const data = useFooterData()

  return (
    <section className={styles.footer}>
      <div className="row">
        {data.body.map(item => (
          <div
            key={item.items[0].image_link.url}
            className={cx(
              `medium-${item.primary.column_sizing}`,
              "columns",
              styles.columnContainer
            )}
          >
            {item.primary.additional_text.text ? (
              <span className={styles.span}>
                {item.primary.additional_text.text}
              </span>
            ) : (
              <></>
            )}
            {item.items.map(image => (
              <a
                key={image.image_link.url}
                href={image.image_link.url}
                target={image.image_link.target}
                rel="noopener"
                className={styles.imageLink}
                style={{
                  display: "inline-block",
                  height: "50px",
                  width: image.image.fluid.aspectRatio * 50,
                }}
              >
                <Img
                  fluid={image.image.fluid}
                  alt={image.image.alt}
                  objectFit="contain"
                  imgStyle={{ width: "auto" }}
                  style={{ width: image.image.fluid.aspectRatio * 50 }}
                  role="img"
                  aria-label={image.label.text}
                />
              </a>
            ))}
          </div>
        ))}
      </div>
    </section>
  )
}

export default Footer
