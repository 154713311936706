import React from "react"
import styles from "./submitting.module.scss"
import useIEVersion from "../../hooks/use-ie-version"

const Submitting = () => {
  return (
    <div className={styles.cover}>
      <div className={styles.centered}>
        {useIEVersion() ? (
          <img
            className={styles.gifLoader}
            src="https://images.prismic.io/risingyouth/e44621d7-885e-4816-a63e-879cac4ce961_loader.gif?auto=compress,format"
            alt=""
          />
        ) : (
          <div className={styles.loader} />
        )}
        <h3>Submitting...</h3>
      </div>
    </div>
  )
}

export default Submitting
