import { useStaticQuery, graphql } from "gatsby"

const useFooterData = () => {
  const data = useStaticQuery(graphql`
    {
      prismicFooter {
        data {
          body {
            ... on PrismicFooterBodyFooterSection {
              items {
                image {
                  fluid(maxHeight: 50) {
                    aspectRatio
                    ...GatsbyPrismicImageFluid
                  }
                  alt
                }
                label {
                  text
                }
                image_link {
                  target
                  url
                }
              }
              primary {
                column_sizing
                additional_text {
                  text
                }
              }
            }
          }
        }
      }
    }
  `)
  return data.prismicFooter.data
}

export default useFooterData
